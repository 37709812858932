<template>
	<div>
		<span v-if="item.status == 'Issued'">
			<b-badge variant="success">{{ item.status }}</b-badge>
		</span>
		<span v-if="item.status == 'Returned'">
			<b-badge variant="primary">{{ item.status }}</b-badge>
		</span>
		<span v-if="item.status == 'Cancelled'">
			<b-badge variant="danger">{{ item.status }}</b-badge>
		</span>
	</div>
</template>

<script>
export default {
	name: 'asset-issuance-row-status',
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style>
</style>

